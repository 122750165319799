<template>
    <div class="yxq-body">
<!--        <van-sticky>-->
<!--            <van-nav-bar title="考试成绩" left-arrow @click-left="goBack"/>-->
<!--        </van-sticky>-->
        <!--错误提示 -->
        <div class="ysw-error" v-if="error">
            <van-image :src="require('../../../public/otherImg/empty-image-default.png')" width="180px"/>
            <p>{{errorMsg}}</p>
        </div>
        <!--加载-->
        <van-loading size="24px" vertical v-else-if="loading">
            加载中...
        </van-loading>
        <!--展示的数据-->
        <div v-else>
            <van-sticky>
            <div class="yxq-exam">
                <div style="text-indent: 1em;margin-bottom: 10px;">{{examinationName}}</div>

                <van-col span="6" style="text-align: center">{{examinationGrade}}</van-col>
                <van-col span="7" style="text-align: center">{{className}}</van-col>
                <van-col span="11" style="text-align: center">{{student.realName}}({{student.examingNumber}})</van-col>

                <div class="van-clearfix"></div>
            </div>
            </van-sticky>
            <van-grid :column-num="3" :gutter="8" square>
                <van-grid-item>
                    <div style="font-size: 30px">{{examination.fullMark}}</div>
                    <van-tag style="padding: 5px 10px" round type="primary">满分</van-tag>
                </van-grid-item>

                <van-grid-item>
                    <div style="font-size: 30px">{{examination.studentTotalScore}}</div>
                    <van-tag style="padding: 5px 10px" round type="primary">得分</van-tag>
                </van-grid-item>

                <van-grid-item>
                    <div style="font-size: 30px">{{examination.rank}}</div>
                    <van-tag style="padding: 5px 10px" round type="primary">等级</van-tag>
                </van-grid-item>

                <van-grid-item v-if="false">
                    <div>
                        <span style="font-size: 30px">{{examination.rankingClass}}</span>
                        <span class="font-11 text-success" v-if="examination.contrast && examination.contrast.rankingClass>0">↑{{examination.contrast.rankingClass}}</span>
                        <span class="font-11 text-danger" v-else-if="examination.contrast && examination.contrast.rankingClass<0">↓{{Math.abs(examination.contrast.rankingClass)}}</span>
                    </div>
                    <van-tag round type="primary">班排</van-tag>
                </van-grid-item>

                <van-grid-item v-if="false">
                    <div>
                        <span style="font-size: 30px">{{examination.rankingCampus}}</span>
                        <span class="font-11 text-success" v-if="examination.contrast && examination.contrast.rankingCampus>0">↑{{examination.contrast.rankingCampus}}</span>
                        <span class="font-11 text-danger" v-else-if="examination.contrast && examination.contrast.rankingCampus<0">↓{{Math.abs(examination.contrast.rankingCampus)}}</span>
                    </div>
                    <van-tag round type="primary">年排</van-tag>
                </van-grid-item>

                <van-grid-item v-if="false">
                    <div>
                        <span style="font-size: 30px">{{examination.rankingSchool}}</span>
                        <span class="font-11 text-success" v-if="examination.contrast && examination.contrast.rankingSchool>0">↑{{examination.contrast.rankingSchool}}</span>
                        <span class="font-11 text-danger" v-else-if="examination.contrast && examination.contrast.rankingSchool<0">↓{{Math.abs(examination.contrast.rankingSchool)}}</span>
                    </div>
                    <van-tag round type="primary">校排</van-tag>
                </van-grid-item>

            </van-grid>

            <van-divider>各科成绩</van-divider>

            <van-panel class="subject_analysis" v-for="(item, index) in tests" :key="index">
                <template #header>
                    <div class="van-cell van-panel__header">
                        <van-icon name="medal" class="van-cell__left-icon"/>
                        <div class="van-cell__title">{{item.subjectName}}</div>
                        <a style="color: #1a9fff" v-on:click="goTestAnalysis(item.testid)">更多分析</a>
                    </div>
                </template>
                <van-col span="6">
                    <van-circle v-model="item.studentTotalScoreRate" :rate="item.studentTotalScoreRate" :speed="100" :stroke-width="50" :size="75" style="line-height: 75px;" layer-color="#f7f8fa">
                        <span class="font-14">{{item.studentTotalScore}}</span>
                        <span class="font-9">/{{item.fullMark}}</span>
                    </van-circle>
                </van-col>

                <van-col span="9">
                    等级：{{item.rank}}
                </van-col>
                <!--<van-col span="9">
                    <span>班排：{{item.rankingClass}}</span>
                    <span class="font-11 text-success" v-if="item.contrast && item.contrast.rankingClass>0">↑{{item.contrast.rankingClass}}</span>
                    <span class="font-11 text-danger" v-else-if="item.contrast && item.contrast.rankingClass<0">↓{{Math.abs(item.contrast.rankingClass)}}</span>
                </van-col>-->
<!--                <van-col span="9">
                    <span v-if="false">年排：{{item.rankingCampus}}</span>
                    <span class="font-11 text-success" v-if="item.contrast && item.contrast.rankingCampus>0">↑{{item.contrast.rankingCampus}}</span>
                    <span class="font-11 text-danger" v-else-if="item.contrast && item.contrast.rankingCampus<0">↓{{Math.abs(item.contrast.rankingCampus)}}</span>
                </van-col>-->
                <!--<van-col span="9">
                    <span>校排：{{item.rankingSchool}}</span>
                    <span class="font-11 text-success" v-if="item.contrast && item.contrast.rankingSchool>0">↑{{item.contrast.rankingSchool}}</span>
                    <span class="font-11 text-danger" v-else-if="item.contrast && item.contrast.rankingSchool<0">↓{{Math.abs(item.contrast.rankingSchool)}}</span>
                </van-col>
-->
                <div class="van-clearfix"></div>
            </van-panel>

            <div v-for="(item,index) in describe" :key="index" style="padding: 5px 8px;background: #fff;">
                <van-divider content-position="left" v-if="item.content!=''">{{item.title}}</van-divider>
                <p>{{item.content}}</p>
            </div>
        </div>

    </div>
</template>

<script>
    import { examinationAnalysisApi } from '@/api/index'

    export default {
        name: "studentExamination",
        data() {
            return {
                loading: false,
                examinationId:0,
                schoolId:0,
                studentId: 0,

                error:false,
                errorMsg:'找不到数据',

                examinationName:'',
                examinationGrade:'',
                examinationLevel:'',
                className:'',
                student:{
                    username:'',
                    realName:'',
                    examingNumber:''
                },
                //试卷分析
                tests:[{
                    subjectName:'',
                }],
                //学科优劣分析
                describe:[],
                //考试分析
                examination:{},
            }
        },
        activated() {
            console.log('stdent examination activated:',this.examinationId,this.studentId,this.$route.query)
            let isLoad = false;
            let examinationId = parseInt(this.$route.query.examinationId);
            let studentId = parseInt(this.$route.query.studentId);
            if (this.examinationId !== examinationId){
                this.examinationId = examinationId
                isLoad = true
            }
            if (this.studentId !== studentId){
                this.studentId = studentId
                isLoad = true
            }
            if (isLoad){
                this.singleStudentAnalysis()
            }
        },
        mounted() {
            // this.singleStudentAnalysis()
        },
        methods: {
            singleStudentAnalysis(){//获取单个学生的考试分析
                this.loading = true;
                let params = {
                    examinationid:this.examinationId,
                    schoolid:this.schoolId,
                    studentid:this.studentId
                }
                examinationAnalysisApi.singleStudentAnalysis(params).then(res => {
                    this.loading = false;

                    if (res.success){
                        this.student = res.data.information.student;
                        this.examinationName = res.data.information.examinationName;
                        this.examinationGrade = res.data.information.gradeName;
                        this.examinationLevel = res.data.information.level;
                        this.className = res.data.information.className;
                        this.tests = [];
                        this.examination = {};
                        this.describe = res.data.describe;
                        for(let item of res.data.data){
                            if (item.testid>0){
                                this.tests.push(item);
                            } else {
                                this.examination = item;
                            }
                        }
                    } else {
                        this.error = true;
                        this.errorMsg = res.msg;
                    }
                });
            },
            goBack() {//返回
                history.back()
            },
            goTestAnalysis(testid) {
                this.$router.push({
                    name: 'studentTestPaper',
                    query: {
                        examinationId: this.examinationId,
                        testId: testid,
                        schoolId: this.schoolId,
                        studentId: this.studentId
                    }
                })
            }
        },
        watch:{
            /*$route(to,form){
                console.log(to.query,form.query)
                this.examinationId = to.query.examinationId
                this.schoolId = to.query.schoolId
                this.studentId = to.query.studentId
            },*/
        },
        filters: {}
    }
</script>

<style lang="scss" scoped>
.yxq-body {
    background-color: #f7f8fa;
    min-height: 100vh;
    height: 100%;

    .font-14{
        font-size: 14px;
    }
    .font-11{
        font-size: 11px;
    }
    .font-10{
        font-size: 10px;
    }
    .font-9{
        font-size: 9px;
    }
    .text-success{
        color: #28a745 !important;
    }
    .text-danger{
        color: #dc3545 !important;
    }
    .yxq-exam {
        background-color: #fff;
        padding: 15px 5px;
        margin-bottom: 15px;
        background-repeat:no-repeat;
        background-position: right bottom;
        background-size: 80px 80px;
    }
    .van-panel {
        width: 96%;
        margin: auto;
        margin-bottom: 20px;
        /*box-shadow: 1px 1px 5px #d4d3d3;*/
        border-radius: 8px;
        padding-bottom: 10px;

        .van-panel__header {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .van-cell__title {
                font-weight: bolder;
            }
        }
        .van-panel__content,.van-panel-content{
            padding: 0 5px 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .van-panel__header-value{
            color: #969799;
        }
        .van-icon{
            color: #0889ce;
        }
    }

    .van-col{
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .van-grid {
        .van-grid-item {
            .van-grid-item__content {
                border-radius: 5px;
            }
        }
    }

    .van-loading{
        justify-content: center;
        height: 90vh;
    }
    .ysw-error{
        height: 60vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;

        p{
            margin: 0px;
            color: #969799;
            font-size: 14px;
            vertical-align: middle;
        }
    }
}

</style>
